/* You can add global styles to this file, and also import other style files */
@import "~rabobank-utilities/rabobank-styles";
@import "~rabobank-layout/rabobank-layout";

.table {
    tr.build-successful {
        td {
            background-color: rgba($success, 0.3);
        }
    }

    tr.build-failed {
        td {
            background-color: rgba($danger, 0.3);
        }
    }

    tr.build-building {
        td {
            background-color: rgba($warning, 0.8);
        }
    }
}
